:root {
  --footer-blu: #101B34;
  --dark-blu: #111E3B;
  --card-dark-blu: #17294A;
  --light-blu: #61A9FC;
  --lb-transp: rgba(97,169,252,0.1);
  --lb-transp-hover: rgba(97,169,252,0.2);
  --lb-transp-3: rgba(97,169,252,0.07);
  --grey: #7485AA;    
  --white: #F4F8FB;
  --gradient: linear-gradient(35Deg, #61A9FC, #315BCE);
  --titles: 'Playfair Display', serif;
  --text: 'Work Sans', sans-serif;

  --kern: -.4px;

  --glow-shadow: 0px 0px 40px rgba(97,169,252,0.2);
  --glow-shadow-hover: 0px 0px 60px rgba(97,169,252,0.5);
}

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body,html {
  margin:0;
  color:var(--grey);
  font-family: var(--text);
  font-weight:400;
  text-rendering: geometricPrecision;
  
}

.multistep {
  background-color:var(--dark-blu);
  width:100%;
  min-height: 100svh;
  background-image:url('../../img/backgrounds/background.png');
  background-size:cover;
}

header {
  position:fixed;
  top:0;
  width:100%;
  height:80px;
  display:flex;
  align-items: center;
  justify-content: center;
}

header img {
  width:114px;
  
}

a {
  color:var(--light-blu);
  text-decoration: underline;
  font-weight:600;
}

.area {
  width:calc(100% - 40px);
  display:flex;
  align-items: center;
  justify-content: center;
  min-height:100svh;
  padding:80px 0px 40px 0px;
  margin:auto;
  position:relative;
  perspective: 800px;
}

.bold {
  font-weight: 600;
}

.white {
  color:var(--white);
}

.light-blu {
  color:var(--light-blu);
}

.small {
  font-size:13px;
}

.title-small {
  font-size:28px;
  font-weight:800;
  line-height:1.2em;
}


.account-vaidation .titles {
  margin-bottom:0;
}
.titles {
  font-size:42px;
  font-family: var(--titles);
  margin-top:0;
  margin-bottom:8px;
  font-weight: 400;
  line-height:1.2em;

}

.subtitle {
  color:var(--light-blu);
  font-weight:600;
  margin:0;
}

.text-big {
  font-size:20px;
  font-weight:600;
  margin-top:0;
  margin-bottom: 4px;
}

p {
  font-size:16px;
  line-height:1.6em;
  margin:0;
}

.text-small {
  font-size:13px;
  margin-top:0;
}

.big-amount {
  font-size:40px;
  margin:0;
  font-weight: 300;
}


/* generic card*/
.generic-card {
  width:460px;
  position:relative;
  
}


.glow-back:before {
  content:"";
  width:calc(100% + 0px);
  height:calc(100% + 0px);
  position:absolute;
  left:-0px;
  top:-0px;
  background:var(--gradient);
  border-radius:30px;
  transition-duration:.3s;
  opacity:0;
  box-shadow: var(--glow-shadow);
}

.glow-back:hover:before{
  opacity:1;
  width:calc(100% + 6px);
  height:calc(100% + 6px);
  left:-3px;
  top:-3px;
}


.generic-card .wrappo {
  border-radius:28px;
  padding-top:60px;
  background-color:var(--card-dark-blu);
  position:relative;
  overflow:hidden;
}

.steps-action {
  display:flex;
  align-items: center;
  justify-content: center;
  gap:20px;
  margin-top:40px;
}

.button {
  text-transform: uppercase;
  font-weight:800;
  font-size:18px;
  color:var(--white);
  border-radius:50px;
  padding:14px 38px;
  transition-duration: .3s;
  line-height:1em;
  display:inline-block;
  text-decoration: none;
  border:none;
  font-family: var(--text);
  text-align: center;
  width:178px;
  text-align: center;
  cursor:pointer;
}

.primary {
  background:var(--gradient);
  box-shadow: var(--glow-shadow);
}

.primary:hover {
  box-shadow: var(--glow-shadow-hover);
  transform:scale(1.015);
}
.secondary {
  background: var(--lb-transp);
}

.secondary:hover {
  transform:scale(1.015);
  background: var(--lb-transp-hover);
}


#step-1,
#step-2,
#step-3,
#step-4,
#step-5 {
  position:relative;
}

.step-nav {
  display:flex;
  gap:10px;
  align-items: center;
  justify-content: center;
  width:100%;
  position: absolute;
  top: 20px;
  left: calc(50%);
  transform: translateX(-50%);
  
}

.step-nav .stp {
  width:12px;
  height:12px;
  border-radius:100%;
  background:var(--gradient);
  opacity:.3;
  display:flex;
  align-items: center;
  justify-content: center;
  color:var(--white);
}

.step-nav .stp p {
  color:var(--white);
}

.step-nav .stp.done {
  opacity:1;
}

.step-nav .stp.active {
  width:28px;
  height:28px;
  opacity:1;
}


.account-vaidation .text-small {
  margin-top:20px;
}


.generic-card .top {
  padding:0px 30px 40px 30px;
  text-align: center;
}

.generic-card .bottom {
  padding:40px;
  background:var(--dark-blu);
  text-align: center;
}

input {
  border:none;
  padding:16px 12px;
  background-color: var(--lb-transp-3);
  border-radius: 12px;
  width:100%;
  color:var(--white);
  font-family: var(--text);
  box-sizing: border-box;
  text-align: center;
}


input.error {
  border:1px solid red;
}

input::placeholder {
  color:var(--light-blu);
  opacity:.6;
  text-align: center;
}

input:focus-visible,
input:focus {
  color:var(--light-blu);
  outline:none;
  background:var(--lb-transp);

}

input:focus-visible::placeholder,
input:focus::placeholder {
  opacity:0;
}


#account-image {
  position:absolute;
  top:20px;
  left:20px;
  width:34px;
}

#account-image img {
  width:100%;
  border-radius: 100%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
}

#payment-options,
#token-type-options {
  margin-top:30px;
  display: flex;
  flex-direction: row;
  gap:20px;
  flex-wrap: wrap;
}

.token-select {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap:14px;
  padding:12px;
  background:var(--card-dark-blu);
  border-radius:16px;
  min-width: 100%;
  position:relative;
  text-align: left;
  transition-duration: .3s;
  cursor:pointer;
}

.token-select:hover {
  transform:scale(1.02);
}

.token-select::after {
  content:"";
  width:22px;
  height:22px;
  position: absolute;
  right:20px;
  top:50%;
  transform:translateY(-50%);
  border-radius:100%;
  background:var(--gradient);
  opacity:.2;
}

.token-select.selected::after {
  opacity:1;
}

.token-select img {
  width:48px;
}

.token-purchase-selection .top,
.token-options-to-buy .top {
  padding-top:10px;
  padding-bottom:30px;
}

.token-purchase-selection .bottom,
.token-options-to-buy .bottom {
  padding-top:20px;
}

.cont-count {
  display: flex;
  align-items: center;
  gap:10px;
  justify-content: center;
}
.cont-count img {
  width:20px;
}

.input-amount {
  margin:30px 0px 0px 0px;
  position: relative;
}

.input-amount input::placeholder {
  color:var(--white);
  opacity:1;
}

.input-amount input:focus-visible::placeholder,
.input-amount input:focus::placeholder {
  opacity:0;
}

.input-amount::after {
  background-image: url('../../img/tokens/hypha-icon.png');
  background-size: 26px;
  content: "";
  height: 26px;
  position: absolute;
  right: 16px;
  top: 15px;
  width: 26px;
}

.input-amount input {
  font-size:22px;
  font-weight:300;
}

#converter {
  display: flex;
  font-size: 14px;
  justify-content: center;
  margin-top: 20px;
  color:var(--white);
}

.conv-cript {
  border-right: 1px solid var(--light-blu);
  padding-right: 10px;
}

.conv-usd {
  margin-left: 10px;
}

.amount-to-choose .bottom {
  padding-top:30px;
}

#total-hypha-to-buy {
  color:var(--white);
  font-size:22px;
}

.final-step #selected-method {
  color:var(--white);
}

.final-step h2 {
  margin-bottom:20px;
  margin-top:10px;
}

.final-step .top {
  padding-bottom:25px;
}

.final-step .bottom {
  padding-top:25px;
}

.btc-bill-card,
.eos-bill-card {
  background:var(--card-dark-blu);
  border-radius: 12px;
  margin: 0;
  padding: 18px;
  text-align: left;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: 100%;
  color:var(--light-blu);
  font-size:14px;

}

.btc-address-title div {
  display: flex;
  justify-content: space-between;
}

#hypha-btc-address {
  font-weight: 600;
  color:var(--white);
}

.copy {
  background: url('../../img/icons/copy-icon.svg');
  background-size: 18px;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  height: 20px;
  margin-left: 20px;
  position: relative;
  width: 24px;
}

.btc-amount-space {
  border-top: 1px solid var(--lb-transp);
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  padding-top: 12px;
}

#total-btc-to-send {
  color:var(--white);
  font-weight:600;
  margin-left:10px;
}


.address-title, .eos-amount-space, .memo-space {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.eos-amount-space, .memo-space {
  border-top: 1px solid var(--lb-transp);
  margin-top: 14px;
  padding-top: 10px;
}

#hypha-eos-address,
#hypha-eos-memo,
#total-eos-to-send,
#total-eos-to-send {
  color:var(--white);
  margin-left:10px;
}

.btc-bottom {
  text-align: left;
  display:flex;
  gap:10px;
  margin-top:20px;
  align-items: center;
}

.btc-bottom #btc-qr-code {
  width:160px;
  min-width: 160px;
}

.btc-bottom .text-small {
  margin-top:10px;
}


.btc-bottom #btc-qr-code img {
  width:100%;
}

.eos-bottom {
  margin-top:20px;
}

.explainer {
  position: relative;
  text-align: center;
  font-size: 12px;
  font-style: italic;
  width: 100%;
  bottom: 0;
  margin-top: 40px;
}

.error-message {
  color: red;
  font-size: 13px;
  margin-top: 10px;
}

@media screen and (max-width : 500px) {
  .generic-card {
      width:100%;
  }

  .button {
      width:calc(50% - 10px);
  }

  .generic-card .bottom {
      padding:20px;
  }

  .generic-card .top {
      padding:0px 20px 20px 20px;
  }

  .titles {
      font-size:36px;
  }

  .token-purchase-selection .top, 
  .token-options-to-buy .top {
      padding-bottom:20px;
  }

  .btc-bottom {
      text-align:center;
  }

  .btc-bottom .white.bold,
  .btc-bottom #btc-qr-code  {
      display:none;
  }

  #hypha-btc-address {
      font-size:11px;
  }

}

.custom-loader {
  width:12px;
  height:12px;
  background: #61a9fc;
  border-radius: 50%;
  box-shadow: 20px 0 #61a9fc22,-20px 0 #61a9fc;
  animation:d5 1s infinite linear alternate;
}
@keyframes d5 {
    0% {box-shadow: 20px 0 #61a9fc,-20px 0 #61a9fc22;background: #61a9fc}
    33%{box-shadow: 20px 0 #61a9fc,-20px 0 #61a9fc22;background: #61a9fc22}
    66%{box-shadow: 20px 0 #61a9fc22,-20px 0 #61a9fc;background: #61a9fc22}
}